*{
  margin: 0;
  padding: 0;
}

body{
  background: black;
}

.main{
  display: flex;
  justify-content: center;
  
}

.box{
  width: 200px;
  height: 200px;
  background: none;
  position: relative;
}


/*====== MEDIA QUERIES (MEDIUM DEVİCES */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .box{
    width:350px;
    height: 350px;
  }
}

