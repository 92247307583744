header {
  min-height: 100vh;
  padding-top: 4rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* ======== CTA  ======== */

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ======== HEADER SOCIALS  ======== */

.header__socialss {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  top: 1rem;

}

.language-buttons button {
  height: 40px !important;
  width: 50px;
  padding: 5px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid black;
  z-index: 1000 !important;

}
.language-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;

  position: absolute;
  right: 0;
  top: 40px;

  height: 100px;
  width: 100px;
  padding: 5px;

}







/* ======== ME  ======== */
.me {
  margin-top: 10px;

}




/* ======== SCROLL DOWN  ======== */



/*====== MEDIA QUERIES (MEDIUM DEVİCES */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  header {
    height: 68vh;
  }

  .header__container {
    margin-top: 150px;

  }

  .text-name{
    font-size: 33px;
  }
  .text-light{
    font-size: 18px;
  }

  .slogan-title {
    font-size: 30px;
  }
}


/*====== MEDIA QUERIES (SMALL DEVİCES) */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .header__socialss, .scroll__down {
    display: none;
  }

  .language-buttons {
    gap: 0.2rem;
  
  }

  .language-buttons button {
    margin-top: 120px;
    height: 50px;
    width: 50px;
    padding: 5px;
    font-size: 15px;
    cursor: pointer;
    border-radius: 10px;

  
  }
  
 
  
  
  
  .language-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 30px;
    top: -110px;
    height: 50px;
    width: 50px;
    padding: 5px;
    flex-direction: row;
  
  }
  
  

  

}


/*====== MEDIA QUERIES (BIG MONITORS) */
@media screen and (min-width: 1850px) and (max-width: 2600px) {
  .header__container {
    margin-top: 150px;

  }
  .text-name{
    font-size: 34px;
  }
  .text-light{
    font-size: 22px;
  }

  .slogan-title {
    font-size: 38px;
  }
}