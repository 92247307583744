.card-container { 
  display: flex;
  flex: column;
  padding: 15px;
  align-items: center;
  min-height: 150px;
}

.image-itself{
  border-radius: 100%;
width: 110px;
height: 110px;



}

.text-wrapper {
  margin-left: 10px;
}

.date-wrapper {
  display: flex;
  flex-direction: rows;
}