.services__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
}

.services__container-new {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 3rem;
  margin-top: 5rem;
}



.service {
  border-radius: 0 0 2rem 2rem;
  border: 1px solid white;
  height: fit-content;
  transition: var(--transition);
}
.service:hover {
  background: transparent;
  cursor: default;
  border: 3px solid  !important;
}
.service__head {
  background: var(--color-primary);
  padding: 2rem;
  border-radius: 0 0 2rem 2rem;
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service-new {
  border-radius: 0 0 2rem 2rem;
  border: 1px solid white;
  height: fit-content;
  transition: var(--transition);
}
.service-new:hover {
  background: transparent;
  cursor: default;
  border: 3px solid  !important;
}
.service__head-new {
  background: var(--color-primary);
  padding: 2rem;
  border-radius: 0 0 2rem 2rem;
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.triple-items{
  display: grid;
  grid-template-columns: repeat(3, 1fr);

}



.service__head-new h3 {
  color: var(--color-bg);
  font-size: 1rem;
  text-align: center;
}
.service__head h3 {
  color: var(--color-bg);
  font-size: 1rem;
  text-align: center;
}
.service__list {
  padding: 2rem;
}

.service__list li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
}
.service__list-icon {
  color: var(--color-primary);
  margin-top: 2px;
}

.service__list p {
  font-size: 0.9rem;
}

.card-title {
  font-size: 20px;
}

.card-desc {
  font-size: 15px;
}

.card-date-start {
  font-size: 15px;
}
.card-date-end {
  font-size: 15px;
}
/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .services__container {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    
  }

  .services__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 3rem;
    
    
  }
  .triple-items{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  
  }

  .service {
    height: auto;
  }

  .triple-items{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: start;
  
  }
  .services__container-new {
  
    
  }
  
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 700px) {

  .services__container {
    display: flex;
    flex-direction: column;
  }

  .service:hover {
    background: transparent;
    cursor: default;
    border: 1px solid  !important;
  }

  .services__container-new {
    gap: 1rem;
 

  }

  .triple-items{
    display: grid;
    padding: 0 10px;
  }

  .card-title {
    font-size: 12px;
  }
  
  .card-desc {
    font-size: 9px;
  }
  
  .card-date-start {
    font-size: 9px;
  }
  .card-date-end {
    font-size: 9px;
  }

  
  
}
